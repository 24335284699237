import React, { useState, useEffect, useRef } from 'react';
import { ListGroup, InputGroup, FormControl, Button } from 'react-bootstrap';
import { onError } from '../libs/errorLib';
import { API } from 'aws-amplify';

import './TrendQuery.css';

const domainRe = /\w+\.\w+/;

export default function TrendQueries({
    ...props
}) {
  const [queries, setQueries] = useState(props.queries);
  const [current, setCurrent] = useState(null);
  const [hidden, setHidden] = useState(false);
  const currentRef = useRef(null);

  useEffect(() => {
    if (current !== null) {
      currentRef.current.focus();
    }
  }, [queries]);

  function setQuery(index, value) {
    setCurrent(index);
    if (index == queries.length) {
      setQueries([...queries, value]);
    } else {
      queries[index] = value;
      setQueries([...queries]);
    }
  }

  function removeQuery(index) {
    const newQueries = [...queries];
    newQueries.splice(index, 1);
    setQueries(newQueries);
    setCurrent(null);
  }

  function parseQuery(index, query) {
    try {
      const url = new URL(query);
      if (url.host === 'trends.google.com') {
        // A pasted google trends query, keep only the "q="
        setQuery(index, url.searchParams.get('q'));
      } else {
        setQuery(index, query);
      }
    } catch (e) {
      // Not a pasted URL
      setQuery(index, query);
    }
  }

  function openQuery(query) {
    if (domainRe.test(query)) {
      window.open(`http://${query}/`, "_url");
    } else {
      window.open(`https://trends.google.com/trends/explore?date=today%205-y&q=${query}`, "_trends");
    }
    window.focus();
  }

  function renderQuery(query, index, removable = true, placeholder = '') {
    return (
      <InputGroup key={`trend_query_${index}_${query}`}>
        <FormControl
          ref={index === current ? currentRef : null}
          defaultValue={query}
          placeholder={placeholder}
          onChange={(e) => parseQuery(index, e.target.value)}
        />
        {removable ?
          <InputGroup.Append>
            <Button
              variant="outline-secondary"
              onClick={(e) => removeQuery(index)}
            >❌️</Button>
          </InputGroup.Append> : <></>
        }
        {query !== '' ?
          <InputGroup.Append>
            <Button
              variant="outline-secondary"
              onClick={() => openQuery(query)}
            >➡️</Button>
          </InputGroup.Append> : <></>
        }
      </InputGroup>
    );
  }

  function renderQueries(queries) {
    return queries.map((q, i) => renderQuery(q, i));
  }

  async function approve() {
    await API.post('trends', `/approve/${props.company_id}` , {
        body: {
            queries: queries,
        }
    });
    setHidden(true);
  }

  async function skip() {
    await API.post('trends', `/skip/${props.company_id}`);
    setHidden(true);
  }

  return (
    hidden ? <></> : 
    <ListGroup.Item>
      {props.company}
      {renderQueries(queries)}
      {renderQuery('', queries.length, false, 'Add new / paste trends URL')}
      <Button
        className="approve"
        variant="success"
        size="sm"
        onClick={() => approve()}
      >Approve</Button>
      <Button
        className="skip"
        variant="warning"
        size="sm"
        onClick={() => skip()}
      >Skip</Button>
     </ListGroup.Item>
  );
}