import React, { useState, useEffect } from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Nav, Navbar, Container } from 'react-bootstrap';
import { AppContext } from './libs/contextLib';
import { onError } from './libs/errorLib';
import { API, Auth, Hub } from 'aws-amplify';
import './App.css';
import Routes from "./Routes";
import AnimatedEllipsis from 'react-animated-ellipsis';


function App() {
  const history = useHistory();
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [userSettings, setUserSettings] = useState({});
  const { pathname, search } = useLocation();


  useEffect(() => {
    async function onLoad() {
      try {
        var session = await Auth.currentSession();
        await loadUserSettings();
        userHasAuthenticated(true);
      } catch (e) {
        let buff = new Buffer(`${pathname}${search}`);
        await Auth.federatedSignIn({
          customState: buff.toString('base64')
        });
        return;
      }

      setIsAuthenticating(false);
    }

    Hub.listen("auth", ({ payload: { event, data } }) => {
      switch (event) {
        case "customOAuthState":
          let buff = new Buffer(decodeURIComponent(data), 'base64');
          let redir = buff.toString('ascii');
          history.push(redir);
      }
    });

    onLoad();
 }, []);

 async function handleLogout() {
    await Auth.signOut();

    userHasAuthenticated(false);

    history.push('/login');
  }

  async function loadUserSettings() {
    try {
      const result = await API.get('settings', '/');
      setUserSettings(result.settings);
    } catch (e) {
      onError(e);
    }
  }

  async function saveUserSettings(settings) {
    if (isAuthenticated) {
      await API.post('settings', '/', { body: { settings: settings }});
      setUserSettings(settings);
    }
  }

  function renderAuthenticated() {
    return (
      <>
        { userSettings.toplist !== true ? <></> :
            <LinkContainer to="/toplist">
              <Nav.Link>Lists</Nav.Link>
            </LinkContainer>
        }
        { userSettings.trends !== true ? <></> :
            <LinkContainer to="/trends">
              <Nav.Link>Trends</Nav.Link>
            </LinkContainer>
        }
        { userSettings.mappings !== true ? <></> :
            <LinkContainer to="/mappings">
              <Nav.Link>Mappings</Nav.Link>
            </LinkContainer>
        }
        <a href="https://sprintscap.retool.com/embedded/public/ad2488f8-a367-4343-bcf3-3494a203afe9?_environment=production" class="nav-link">Intel</a>
        <Nav.Item>
          <Nav.Link onClick={handleLogout}>Logout</Nav.Link>
        </Nav.Item>
      </>
    );
  }

  if (isAuthenticating) {
    return (
      <>
        <Navbar bg="light" className="App">
          <Navbar.Brand>
            <Link to="/">SPiNE</Link> loading<AnimatedEllipsis />
          </Navbar.Brand>
        </Navbar>
      </>
    )
  } else {
    return (
      <>
        <Navbar bg="light" collapseOnSelect expand="lg" className="App">
          <Navbar.Brand>
            <Link to="/">SPiNE</Link>
          </Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse className="justify-content-end">
            <Nav>
              {isAuthenticated
                ? renderAuthenticated()
                :
                <Nav.Link
                  onClick={() => Auth.federatedSignIn()}
                >
                  Login
              </Nav.Link>
              }
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <AppContext.Provider value={{ isAuthenticated, userHasAuthenticated, userSettings, saveUserSettings, loadUserSettings }}>
          <Routes />
        </AppContext.Provider>
      </>
    );
  }
}

export default App;
