export default {
  apiGateway: {
    REGION: 'eu-west-1',
    URL: 'https://1grccjj5sa.execute-api.eu-west-1.amazonaws.com/dev',
  },
  cognito: {
    REGION: 'eu-west-1',
    USER_POOL_ID: 'eu-west-1_xdIK5zYeZ',
    APP_CLIENT_ID: '6edoik0mr0ia7m897g6cmb7rt9',
    IDENTITY_POOL_ID: 'eu-west-1:a71e2cc7-4aa7-413e-b290-c5d85ee8da70',
  }
};