import React from 'react';
import { Tabs, Tab, Container } from 'react-bootstrap';
import Mappings from '../components/Mappings';

import './Trends.css';

export default function CompanyMappings() {
    return (
        <Container>
            <Tabs defaultActiveKey="missing-crunchbase" className="TypeSelector" unmountOnExit={true}>
                <Tab eventKey="missing-crunchbase" title="Missing Crunchbase">
                    <Mappings id="missing-crunchbase" type="missing-crunchbase" />
                </Tab>
                <Tab eventKey="missing-types" title="Missing Company type">
                    <Mappings id="missing-type" type="missing-type" />
                </Tab>
            </Tabs>
        </Container>
    )
}
