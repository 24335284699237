import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify'
import { useAppContext } from '../libs/contextLib';

export default function Logout() {
  const history = useHistory();
  const { userHasAuthenticated } = useAppContext();

  useEffect(() => {
    handleLogout();
    userHasAuthenticated(false);
    history.push('/');
  });

  async function handleLogout() {
    await Auth.signOut();
  }

  return (
    <div className="Logout"></div>
  );
}