import React, { useRef, useState, useEffect } from 'react';
import { ListGroup } from 'react-bootstrap';
import { onError } from '../libs/errorLib'
import { API } from 'aws-amplify'
import TrendQuery from './TrendQuery'

import './TrendQueries.css';

export default function TrendQueries({...props}) {
  const [isLoading, setIsLoading] = useState(true);
  const [trendQueries, setTrendQueries] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    loadQueries();
  }, []);

  async function loadQueries() {
    try {
      const queries = await loadTrendQueries();
      setTrendQueries(queries);
    } catch (e) {
      onError(e);
    }

    setIsLoading(false);
  }

  function loadTrendQueries() {
    return API.get('trends', `?type=${props.type}`);
  }

  return (
    <>
      <ListGroup className="TrendQueries">
        { isLoading ? <></> :
          <>
              {trendQueries.map((tq, i) => (
                  <TrendQuery
                    key={`trend_queries_${i}`}
                    company={tq.company}
                    company_id={tq.company_id}
                    queries={tq.queries}
                  />
               ))
              }
          </>
        }
      </ListGroup>
    </>
  );
}