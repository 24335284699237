import React, { useState, useRef } from 'react';
import { ListGroup, InputGroup, FormControl, Button, Form, Image } from 'react-bootstrap';
import { API } from 'aws-amplify';


export default function TrendQueries({
    ...props
}) {
  const [hidden, setHidden] = useState(false);
  const [crunchbaseId, setCrunchbaseID] = useState(null);
  const [topSuggestion, setTopSuggestion] = useState(null);

  async function saveCrunchbaseID() {
    if (crunchbaseId !== null) {
      await API.post('mappings', `/${props.company_id}`, {
        body: {
          crunchbase_id: crunchbaseId,
        }
      });
    }
    setHidden(true);
  }

  async function getSuggestion(name) {
    var suggestions = await API.get(
      'datasource',
      `/company/suggest`,
      {
        queryStringParameters: {
          q: name,
        },
      }
    );

    if (suggestions.length) {
      setTopSuggestion(suggestions[0]);
      setCrunchbaseID(suggestions[0].id);
    }
  }

  return (
    hidden ? <></> :
      <ListGroup.Item>
        <span
          onClick={(e) => getSuggestion(props.company_name)}
        >{props.company_name}</span><span>   </span>
        {topSuggestion ? <Image src={topSuggestion.image} /> : <></>}
        <a href={`//${props.domain_name}`} target="ref">{props.domain_name}</a> <Image width={40} src={`https://${props.domain_name}/favicon.ico`} />
        <Form.Control
          placeholder="Crunchbase ID"
          value={topSuggestion && topSuggestion.id}
          onChange={(e) => setCrunchbaseID(e.target.value)}
        />
        <Button onClick={() => saveCrunchbaseID()}>
          Save
        </Button>
      </ListGroup.Item>
  );
}