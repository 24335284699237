import React, { useState, useEffect } from 'react';
import { Image, Container } from 'react-bootstrap';
import Table from 'react-bootstrap-table-next';
import { multiSelectFilter } from '../components/MultiSelectFilter';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import { API } from 'aws-amplify';

import { onError } from '../libs/errorLib'

import paginationFactory, { PaginationProvider, PaginationListStandalone, SizePerPageDropdownStandalone } from 'react-bootstrap-table2-paginator';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import './Toplist.css'
import AnimatedEllipsis from 'react-animated-ellipsis';
import TagManager from 'react-gtm-module';
import { dataLayerWithEmail } from '../libs/dataLayerLib';


export default function Toplist({
  category,
  vc,
  streak,
  ...props
}) {
  const [toplist, setToplist] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    onLoad();
  }, []);

  async function onLoad() {
    try {
      let query = '';
      if (category) {
        query = `?category=${category}`
      } else if (vc) {
        query = `?vc=${vc}`
      }
      const toplist = await API.get('toplist', query);

      TagManager.dataLayer({
        dataLayer: await dataLayerWithEmail({
          event: 'API',
          endpoint: 'toplist',
          query: query,
        })
      });

      for (let entry of toplist) {
        entry.funding_prediction = getFundingPrediction(entry);
        entry.class = getClass(entry);
        if (entry.status === null || entry.status === "Listed") {
          entry.status = '-';
        }
        if (entry.owner === null) {
          entry.owner = '-';
        }
      }
      setToplist(toplist);
    } catch (e) {
      onError(e);
    }

    setIsLoading(false);
  }

  function renderLoading() {
    return (<Container>Loading<AnimatedEllipsis /></Container>);
  }

  function formatUSDAmount(amount) {
    if (amount < 1000000) {
      return `$${new Number(amount).toLocaleString()}`
    }
    return `$${new Number(Math.round(amount / 1000000)).toLocaleString()}m`
  }

  function formatRound({ latest_round, latest_round_amount_usd }) {
    if (latest_round !== null && latest_round_amount_usd !== null) {
      return `${latest_round} (${formatUSDAmount(latest_round_amount_usd)})` 
    } else if (latest_round !== null && latest_round_amount_usd === null) {
        return `${latest_round} funding n/a`;
    } else {
      return '';
    }
  }

  function getFundingPrediction({ latest_round }) {
    if (latest_round !== null) {
      const latest_round_date = new Date(latest_round);
      let two_years_ago = new Date();
      two_years_ago.setFullYear(two_years_ago.getFullYear() - 2);

      let one_year_ago = new Date();
      one_year_ago.setFullYear(one_year_ago.getFullYear() - 1);

      let six_months_ago = new Date();
      six_months_ago.setMonth(six_months_ago.getMonth() - 6);

      if (latest_round_date < two_years_ago || latest_round_date > six_months_ago) {
        return '-'
      }
      if (latest_round_date < one_year_ago) {
        return "Very soon";
      }
      return "Soon ";
    } else {
      return '-';
    }
  }


  function formatFundingPrediction({ latest_round }) {
    const funding_prediction = getFundingPrediction({ latest_round })

    if (funding_prediction === 'Very soon') {
        return (<span className="very_soon">Very soon</span>);
    } else if (funding_prediction === 'Soon ') {
      return (<span className="soon">Soon</span>);
    }

    return '-';
  }

  function formatPosition({ position, previous_position, new_entry }) {
    var dir = (<></>);
    if (previous_position !== null) {
      if (previous_position < position) {
        dir = (<span key={`arrow_${position}`} className="down">{"\u21e9"}</span>)
      } else if (previous_position > position) {
        dir = (<span key={`arrow_${position}`} className="up">{"\u21e7"}</span>)
      }
    }
    var n = null;
    if (new_entry) {
      n = (<Tippy content="Added after publication of Toplist"><span className="new">(new)</span></Tippy>)
    }

    return previous_position !== null ? [`${position} (${previous_position})`, dir] : [position, n]
  }

  function formatLastEmail({last_email_date, last_email_persons, is_first_contact}) {
    if (last_email_date && last_email_persons) {
      let days = Math.floor(new Date().getTime() / (1000 * 60 * 60 * 24)) - Math.floor(new Date(last_email_date).getTime() / (1000 * 60 * 60 * 24));
      
      var days_ago;
      if (days == 0) {
        days_ago = 'Today';
      } else if (days == 1) {
        days_ago = 'Yesterday';
      } else {
        days_ago = `${days} days ago`;
      }
      return (
        <>
          {last_email_persons}, {days_ago}
          {is_first_contact ?  <> - <span className="first_contact">1st contact</span></> : ''} 
        </>
      ); 
    }
  }

  function formatStatus(status, previous_status) {
    if (!previous_status || previous_status === status) {
      return status;
    }
    return (<>{status} <span className="previous">({previous_status})</span></>)
  }

  function formatName({company_id, name, domain, short_description}) {
    return (
      <>
        <Tippy content={short_description}><span>{name}</span></Tippy>
        <span className="links">
          <Tippy content="Company website">
            <a className="link" href={`//${domain}`} target="synaptic">🌐</a>
          </Tippy>
          <Tippy content="Synaptic">
            <a href={`https://app.synaptic.com/companies/${company_id}`} target="synaptic"><Image width={20} src={"/synaptic.svg"} /></a>
          </Tippy>
        </span>
      </>
    )
  }

  function getClass({ latest_round_amount_usd }) {
    if (latest_round_amount_usd && latest_round_amount_usd > 50_000_000) {
      return "Capital hungry";
    }
    else return '-';
  }

  const columns = [
    {
      dataField: 'position',
      text: 'Pos',
      sort: true,
      formatter: (cell, row, rowIndex, extra) => formatPosition(row)
    },
    {
      dataField: 'top_60_streak',
      text: 'Streak',
      sort: true,
      hidden: !streak
    },
     {
      dataField: 'name',
      text: '',
      formatter: (cell, row, rowIndex, extra) => formatName(row),
      filter: textFilter({placeholder: "Search..."})
    },
    {
      dataField: 'continent',
      sort: false,
      text: '',
      filter: multiSelectFilter({
        options: () => Object.fromEntries([...new Set(toplist.map(e => e.continent))].sort().filter(e => e !== null).map((r) => [r, r])),
        title: 'Location'
      })
    },
    {
      dataField: 'owner',
      sort: false,
      text: '',
      filter: multiSelectFilter({
        options: () => Object.fromEntries([...new Set(toplist.map(e => e.owner))].sort().filter(e => e !== null).map((r) => [r, r])),
        title: 'Owner'
      })
    },
    {
      dataField: 'status',
      sort: false,
      text: '',
      filter: multiSelectFilter({
        options: () => Object.fromEntries([...new Set(toplist.map(e => e.status))].sort().filter(e => e !== null).map((r) => [r, r])),
        selected: () => [...new Set(toplist.map(e => e.status))].sort().filter(e => e !== null && e !== "Public"),
        preselectAll: false,
        title: 'Status',
      }),
      formatter: (cell, row, rowIndex, extra) => formatStatus(row.status, row.previous_status)
    },
    {
      dataField: 'last_email_date',
      text: 'Last email',
      sort: true,
      sortFunc: (a, b, order, dataField) => {
        if (order === 'asc') {
          if (b === '') return false;
          if (a === '') return true;
          return a > b;
        }
        return b > a;
      },
      formatter: (cell, row, rowIndex, extra) => formatLastEmail(row)
    },
    {
      dataField: 'latest_round',
      text: 'Last round',
      sort: true,
      formatter: (cell, row, rowIndex, extra) => formatRound(row)
    },
    {
      dataField: 'class',
      text: '',
      filter: multiSelectFilter({
        options: () => Object.fromEntries([...new Set(toplist.map(e => e.class))].sort().filter(e => e !== null).map((r) => [r, r])),
        title: 'Class',
      }),
    },
     {
      dataField: 'funding_prediction',
      sort: false,
      text: '',
      filter: multiSelectFilter({
        options: () => Object.fromEntries([...new Set(toplist.map(e => e.funding_prediction))].sort().filter(e => e !== null).map((r) => [r, r])),
        title: 'Fundraising',
      }),
      formatter: (cell, row, rowIndex, extra) => formatFundingPrediction(row)
    }
  ]

  const contentTable = ({ paginationProps, paginationTableProps }) => (
    <div>
      <Table
        responsive
        wrapperClasses="table-responsive"
        bootstrap4
        keyField='company_id'
        data={toplist}
        columns={columns}
        filter={ filterFactory() }
        hover
        { ...paginationTableProps }
      />
      <div class="paginator fixed-bottom">
        <SizePerPageDropdownStandalone { ...paginationProps } />
        <PaginationListStandalone { ...paginationProps } />
      </div>
    </div>
  );
  
  function renderToplist() {
    const options = {
      custom: true,
      paginationSize: 4,
      pageStartIndex: 1,
      sizePerPage: 100,
      variation: "dropup",
      sizePerPageList: [
        {
          text: '50/page',
          value: 50
        }, {
          text: '100/page',
          value: 100
        }
      ],
      onPageChange: () => {window.scrollTo(0, 0)}
    };

    return (
      <PaginationProvider pagination={ paginationFactory(options) }>
        { contentTable}
      </PaginationProvider>
    );
  }

  return (
    <div className="Toplist">
      {isLoading ? renderLoading() : renderToplist()}
    </div>
  );
}