import React, { useState, useEffect } from 'react';
import { Image, Container, Button, Form } from 'react-bootstrap';
import Table from 'react-bootstrap-table-next';
import { multiSelectFilter } from '../components/MultiSelectFilter';
import ConfirmButton from '../components/ConfirmButton';
  
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

import filterFactory from 'react-bootstrap-table2-filter';
import { API } from 'aws-amplify';

import { onError } from '../libs/errorLib'

import paginationFactory, { PaginationProvider, PaginationListStandalone, SizePerPageDropdownStandalone } from 'react-bootstrap-table2-paginator';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import './Universe.css'
import AnimatedEllipsis from 'react-animated-ellipsis';

import TagManager from 'react-gtm-module';
import { dataLayerWithEmail } from '../libs/dataLayerLib';

export default function Universe() {
  const [toplist, setUniverse] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    onLoad();
  }, []);

  async function onLoad() {
    try {
      const toplist = await API.get('universe', '/toplist');

      TagManager.dataLayer({
        dataLayer: await dataLayerWithEmail({
          event: 'API',
          endpoint: 'universe/toplist',
        })
      });

      for (let entry of toplist) {
        entry.funding_prediction = getFundingPrediction(entry);
        entry.class = getClass(entry);
        if (entry.status === null || entry.status === "Listed") {
          entry.status = '-';
        }
        if (entry.owner === null) {
          entry.owner = '-';
        }
        if (entry.monitoring === true) {
          entry.is_monitoring = 'Monitoring ';
        } else {
          entry.is_monitoring = 'Not monitoring';
        }
 
      }
      setUniverse(toplist);
    } catch (e) {
      onError(e);
    }

    setIsLoading(false);
  }

  function renderLoading() {
    return (<Container>Loading<AnimatedEllipsis /></Container>);
  }

  function formatUSDAmount(amount) {
    if (amount < 1000000) {
      return `$${new Number(amount).toLocaleString()}`
    }
    return `$${new Number(Math.round(amount / 1000000)).toLocaleString()}m`
  }

  function formatRound({ latest_round, latest_round_amount_usd }) {
    if (latest_round !== null && latest_round_amount_usd !== null) {
      return `${latest_round} (${formatUSDAmount(latest_round_amount_usd)})` 
    } else {
      return latest_round;
    }
  }

  function formatTotalRaised({ total_raised_usd }) {
    if (total_raised_usd) {
      return formatUSDAmount(total_raised_usd);
    }
  }

  function getFundingPrediction({ latest_round }) {
    if (latest_round !== null) {
      const latest_round_date = new Date(latest_round);
      let two_years_ago = new Date();
      two_years_ago.setFullYear(two_years_ago.getFullYear() - 2);

      let one_year_ago = new Date();
      one_year_ago.setFullYear(one_year_ago.getFullYear() - 1);

      let six_months_ago = new Date();
      six_months_ago.setMonth(six_months_ago.getMonth() - 6);

      if (latest_round_date < two_years_ago || latest_round_date > six_months_ago) {
        return '-'
      }
      if (latest_round_date < one_year_ago) {
        return "Very soon";
      }
      return "Soon ";
    } else {
      return '-';
    }
  }


  function formatFundingPrediction({ latest_round }) {
    const funding_prediction = getFundingPrediction({ latest_round })

    if (funding_prediction === 'Very soon') {
        return (<span className="very_soon">Very soon</span>);
    } else if (funding_prediction === 'Soon ') {
      return (<span className="soon">Soon</span>);
    }

    return '-';
  }

  async function monitorCompany(company_id) {
    await API.post('universe', `/monitor/${company_id}`).then(async () => {
      onLoad();

      TagManager.dataLayer({
        dataLayer: await dataLayerWithEmail({
          event: 'API',
          endpoint: `universe/monitor`,
        })
      });
    }).catch((e) => {
      onError(e);
    });
  }

async function deleteCompany(company_id) {
    await API.del('universe', `/${company_id}`).then(async () => {
      onLoad();

      TagManager.dataLayer({
        dataLayer: await dataLayerWithEmail({
          event: 'API',
          endpoint: `universe/delete`,
        })
      });
    }).catch((e) => {
      onError(e);
    });
  }

  function formatActions({ company_id, monitoring }) {
    return (
        <>
          <ConfirmButton key={company_id} className="delete_button" variant="outline-danger" size="sm" onConfirmed={() => deleteCompany(company_id)}>Delete</ConfirmButton>
          {!monitoring ? <ConfirmButton variant="outline-success" confirmationVariant="success" size="sm" handleSaving={true} onConfirmed={() => monitorCompany(company_id)}>Monitor</ConfirmButton> : ''}
        </>
      )
  }

  function formatPosition({ position, previous_position }) {
    var dir = (<></>);
    if (previous_position !== null) {
      if (previous_position < position) {
        dir = (<span key={`arrow_${position}`} className="down">{"\u21e9"}</span>)
      } else if (previous_position > position) {
        dir = (<span key={`arrow_${position}`} className="up">{"\u21e7"}</span>)
      }
    }
    return previous_position !== null ? [`${position} (${previous_position})`, dir] : position
  }

  function formatStatus(status, previous_status) {
    if (!previous_status || previous_status === status) {
      return status;
    }
    return (<>{status} <span className="previous">({previous_status})</span></>)
  }

  function formatName({company_id, name, domain, monitoring, short_description}) {
    return (
      <>
        <Tippy content={short_description}><span>{name}</span></Tippy>
        <span className="links">
          <Tippy content="Monitored by Spine">
            {monitoring ? <span className="checkmark">✓️</span> : <></>}
          </Tippy>
          <Tippy content="Company website">
            <a className="link" title="Company website" href={`//${domain}`} target="synaptic">🌐</a>
          </Tippy>
          <Tippy content="Synaptic">
            <a href={`https://app.synaptic.com/companies/${company_id}`} title="Synaptic" target="synaptic"><Image width={20} src={"/synaptic.svg"} /></a>
          </Tippy>
        </span>
      </>
    )
  }

  function getClass({ latest_round_amount_usd }) {
    if (latest_round_amount_usd && latest_round_amount_usd > 50_000_000) {
      return "Capital hungry";
    }
    else return '-';
  }

  const columns = [
    {
      dataField: 'position',
      text: 'Pos',
      sort: true,
      formatter: (cell, row, rowIndex, extra) => formatPosition(row)
    },
    {
      dataField: 'top_60_streak',
      text: 'Streak',
      sort: true,
    },
    {
      dataField: 'is_monitoring',
      text: '',
      formatter: (cell, row, rowIndex, extra) => formatName(row),
      sort: false,
      filter: multiSelectFilter({
        options: () => Object.fromEntries([...new Set(toplist.map(e => e.is_monitoring))].sort().filter(e => e !== null).map((r) => [r, r])),
        title: 'Name'
      })
    },
    {
      dataField: 'continent',
      sort: false,
      text: '',
      filter: multiSelectFilter({
        options: () => Object.fromEntries([...new Set(toplist.map(e => e.continent))].sort().filter(e => e !== null).map((r) => [r, r])),
        title: 'Location'
      })
    },
    {
      dataField: 'status',
      sort: false,
      text: '',
      filter: multiSelectFilter({
        options: () => Object.fromEntries([...new Set(toplist.map(e => e.status))].sort().filter(e => e !== null).map((r) => [r, r])),
        selected: () => [...new Set(toplist.map(e => e.status))].sort().filter(e => e !== null && e !== "Public"),
        preselectAll: false,
        title: 'Status',
      }),
      formatter: (cell, row, rowIndex, extra) => formatStatus(row.status, row.previous_status)
    },
    {
      dataField: 'latest_round_amount_usd',
      text: 'Last round',
      sort: true,
      formatter: (cell, row, rowIndex, extra) => formatRound(row),
      sortFunc: (a, b, order, dataField) => {
        if (order === 'asc') {
          return parseInt(a) > parseInt(b);
        } else {
          return parseInt(a) < parseInt(b);
        }
      }
 
    },
    {
      dataField: 'total_raised_usd',
      text: 'Total raised',
      sort: true,
      formatter: (cell, row, rowIndex, extra) => formatTotalRaised(row),
      sortFunc: (a, b, order, dataField) => {
        if (order === 'asc') {
          return parseInt(a) > parseInt(b);
        } else {
          return parseInt(a) < parseInt(b);
        }
      }
    },
    {
      dataField: 'class',
      text: '',
      filter: multiSelectFilter({
        options: () => Object.fromEntries([...new Set(toplist.map(e => e.class))].sort().filter(e => e !== null).map((r) => [r, r])),
        title: 'Class',
      }),
    },
    {
      dataField: 'funding_prediction',
      sort: false,
      text: '',
      filter: multiSelectFilter({
        options: () => Object.fromEntries([...new Set(toplist.map(e => e.funding_prediction))].sort().filter(e => e !== null).map((r) => [r, r])),
        title: 'Fundraising',
      }),
      formatter: (cell, row, rowIndex, extra) => formatFundingPrediction(row)
    },
    {
      dataField: 'actions',
      sort: false,
      text: '',
      formatter: (cell, row, rowIndex, extra) => formatActions(row)
    }
  ]

  const contentTable = ({ paginationProps, paginationTableProps }) => (
    <div>
      <Table
        responsive
        wrapperClasses="table-responsive"
        bootstrap4
        keyField='company_id'
        data={toplist}
        columns={columns}
        filter={ filterFactory() }
        hover
        { ...paginationTableProps }
      />
      <div class="paginator fixed-bottom">
        <SizePerPageDropdownStandalone { ...paginationProps } />
        <PaginationListStandalone { ...paginationProps } />
      </div>
    </div>
  );
   
  function renderUniverse() {
    const options = {
      custom: true,
      paginationSize: 4,
      pageStartIndex: 1,
      sizePerPage: 100,
      variation: "dropup",
      sizePerPageList: [
        {
          text: '50/page',
          value: 50
        }, {
          text: '100/page',
          value: 100
        }
      ],
      onPageChange: () => {window.scrollTo(0, 0)}
    };

    return (
      <PaginationProvider pagination={ paginationFactory(options) }>
        { contentTable}
      </PaginationProvider>
    );
  }

  return (
    <div className="Universe">
      {isLoading ? renderLoading() : renderUniverse()}
    </div>
  );
}