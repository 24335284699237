import React, { useRef, useState, useEffect } from 'react';
import { ListGroup } from 'react-bootstrap';
import { onError } from '../libs/errorLib'
import { API } from 'aws-amplify'
import CrunchbaseMapping from './Mapping'
import TypeMapping from './TypeMapping'

export default function Mappings({...props}) {
  const [isLoading, setIsLoading] = useState(true);
  const [mappings, setMappings] = useState([]);
  const Mapping = props.type == "missing-type" ? TypeMapping : CrunchbaseMapping;

  useEffect(() => {
    setIsLoading(true);
    loadMappings();
  }, []);

  async function loadMappings() {
    try {
      const mappings = await API.get('mappings', `?type=${props.type}`);
      setMappings(mappings);
    } catch (e) {
      onError(e);
    }

    setIsLoading(false);
  }

  return (
    <>
      <ListGroup className="TrendQueries">
        { isLoading ? <></> :
          <>
              {mappings.map((company, i) => (
                  <Mapping
                    key={`mapping_${i}`}
                    company_name={company[1]}
                    domain_name={company[2]}
                    company_id={company[0]}
                  />
               ))
              }
          </>
        }
      </ListGroup>
    </>
  );
}