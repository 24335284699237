import React, { useEffect, useState } from "react";
import { Tab, Row, Col, Nav, NavDropdown, Container } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import { API } from 'aws-amplify';
import { onError } from '../libs/errorLib'

import Toplist from '../containers/Toplist';
import VcToplist from '../containers/VcToplist';
import Universe from '../containers/Universe';

import { useAppContext } from "../libs/contextLib";
import AnimatedEllipsis from 'react-animated-ellipsis';

import "./Toplists.css";

export default function Toplists() {
  const location = useLocation();
  const [selected, setSelected] = useState(new Set([location.hash.substr(1) || 'toplist']));
  const [current, setCurrent] = useState(location.hash.substr(1) || 'toplist');
  const [vcList, setVcList] = useState([]);
  const [industryList, setIndustryList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { isAuthenticated } = useAppContext();
  const history = useHistory();

  useEffect(() => {
    setIsLoading(true);
    onLoad();
  }, []);

  async function onLoad() {
    try {
      const vcs = await API.get('vcs', '');
      setVcList(vcs.map((vc) => {return {key: vc.toLowerCase().replace(' ', '_'), name: vc}}));

      const industries = await API.get('spine-industries', '');
      setIndustryList(industries.map((i) => {return {key: i.toLowerCase().replace(' ', '_'), name: i}}));
    } catch (e) {
      onError(e);
    }

    setIsLoading(false);
  }

  function industryTitle() {
    const found = industryList.find(element => element.key == current);
    if (found) {
      return found.name;
    }
    return "Industries";
  }

  function industryActive() {
    const found = industryList.find(element => element.key == current);
    if (found) {
      return true;
    }
    return false;
  }

  function vcTitle() {
    const found = vcList.find(element => element.key == current);
    if (found) {
      return found.name;
    }
    return "VCs";
  }

  function vcActive() {
    const found = vcList.find(element => element.key == current);
    if (found) {
      return true;
    }
    return false;
  }


  function renderToplistTabs() {
    return (
      <Tab.Container
        activeKey={location.hash.substr(1) || 'toplist'}
        id="toplist-selector"
      >
        <Row>
          <Col>
            <Nav variant="tabs" onSelect={(key) => { history.push(`#${key}`); setSelected(selected.add(key)); setCurrent(key); }}>
              <Nav.Item>
                <Nav.Link eventKey="toplist">Toplist</Nav.Link>
              </Nav.Item>
              <NavDropdown title={industryTitle()} id="nav-dropdown" active={industryActive()}>
                {industryList.map(i => {return (<NavDropdown.Item eventKey={i.key}>{i.name}</NavDropdown.Item>)})}
              </NavDropdown>
              <NavDropdown title={vcTitle()} id="nav-dropdown" active={vcActive()}>
                {vcList.map(vc => {return (<NavDropdown.Item eventKey={vc.key}>{vc.name}</NavDropdown.Item>)})}
              </NavDropdown>
              <Nav.Item>
                <Nav.Link eventKey="universe">Universe</Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
        </Row>
        <Row>
          <Col>
            <Tab.Content>
              <Tab.Pane eventKey="toplist">
                {selected.has("toplist") ? <Toplist streak={true} /> : <></>}
              </Tab.Pane>
              <Tab.Pane eventKey="universe">
                {selected.has("universe") ? <Universe /> : <></>}
              </Tab.Pane>
              {industryList.map(i => {return (
                <Tab.Pane eventKey={i.key}>
                  {selected.has(i.key) ? <Toplist category={i.name} streak={false} /> : <></>}
                </Tab.Pane>
              )})}
              {vcList.map(i => {return (
                <Tab.Pane eventKey={i.key}>
                  {selected.has(i.key) ? <VcToplist vc={i.name} streak={false} /> : <></>}
                </Tab.Pane>
              )})}
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>)
  }

  function renderLanding() {
    return (<div className="lander">
      <h1>SPiNE</h1>
    </div>);
  }

  function renderLoading() {
    return (<Container>Loading<AnimatedEllipsis /></Container>);
  }

  return (
    <div className="Toplists">
      {isAuthenticated ? (isLoading ? renderLoading() : renderToplistTabs()) : renderLanding() }
    </div>
  );
}