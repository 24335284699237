import React, { useRef } from 'react';
import { Container, Tabs, Tab } from 'react-bootstrap';
import TrendQueries from '../components/TrendQueries';

import './Trends.css';

export default function Trends() {
    const statsRef = useRef();

    return (
        <Container>
            <Tabs defaultActiveKey="pending" className="TypeSelector" unmountOnExit={true}>
                <Tab eventKey="pending" title="Pending">
                    <TrendQueries id="pending" type="pending" />
                </Tab>
                <Tab eventKey="approved" title="Approved" mountOnEnter={true} unmountOnExit={true}>
                    <TrendQueries id="approved" type="approved" />
                </Tab>
                <Tab eventKey="skipped" title="Skipped" mountOnEnter={true} unmountOnExit={true}>
                    <TrendQueries id="approved" type="skipped" />
                </Tab>
            </Tabs>
        </Container>
    )
}
