import React, { useState, useRef } from 'react';
import { ListGroup, InputGroup, FormControl, Button, Form, Image } from 'react-bootstrap';
import { API } from 'aws-amplify';


export default function TypeMapping({
    ...props
}) {
  const [hidden, setHidden] = useState(false);
  const [types, setTypes] = useState(new Set());

  async function saveTypes() {
    await API.post('mappings', `/${props.company_id}?type=type`, {
      body: {
        types: Array.from(types),
      }
    });
    setHidden(true);
  }

  function updateType(checkbox, type) {
    if (checkbox.checked) {
      setTypes(types.add(type));
    } else {
      types.delete(type)
      setTypes(types);
    }
  }

  return (
    hidden ? <></> :
      <ListGroup.Item>
        <span>{props.company_name}</span>
        <span>   </span>
        <a href={`//${props.domain_name}`} target="ref">{props.domain_name}</a>
        <Form.Group className="mb-3">
            <Form.Check
              inline
              onChange={(e) => updateType(e.target, "web")}
              type="checkbox"
              label="Web"
              id="web"
            />
            <Form.Check
              inline
              onChange={(e) => updateType(e.target, "app")}
              type="checkbox"
              label="App"
              id="app"
            />
            <Form.Check
              inline
              onChange={(e) => updateType(e.target, "other")}
              type="checkbox"
              label="Other"
              id="other"
            />
        </Form.Group>
        <Button onClick={() => saveTypes()}>
          Save
        </Button>
      </ListGroup.Item>
  );
}