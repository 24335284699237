/* eslint react/require-default-props: 0 */
/* eslint no-return-assign: 0 */
/* eslint no-param-reassign: 0 */
/* eslint react/no-unused-prop-types: 0 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DropdownMultiselect from "react-multiselect-dropdown-bootstrap";

class DropdownMultiselectCustom extends DropdownMultiselect {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        if (this.props.preselectAll === true) {
            let selections;
            if (typeof this.props.options[0] === "object") {
                selections = this.props.options.map((value) => {
                    return value[this.props.optionKey];
                });
            } else if (typeof this.props.options[0] === "string") {
                selections = this.props.options;
            }

            this.setState({ selected: selections });
        }
        super.componentDidMount()
    }

    getPlaceholderValue() {
        return `${this.props.placeholder} (${this.state.selected.length}/${this.state.options.length})`;
    }

    getOptions() {
        return this.props.options;
    }
}

function optionsEquals(currOpts, prevOpts) {
    const keys = Object.keys(currOpts);
    for (let i = 0; i < keys.length; i += 1) {
        if (currOpts[keys[i]] !== prevOpts[keys[i]]) {
            return false;
        }
    }
    return Object.keys(currOpts).length === Object.keys(prevOpts).length;
}

const getSelections = (container) => {
    return container.selected;
};

class MultiSelectFilter extends Component {
    constructor(props) {
        super(props);
        this.filter = this.filter.bind(this);
        this.applyFilter = this.applyFilter.bind(this);
        const isSelected = props.defaultValue.map(item => props.options[item]).length > 0;
        this.state = { isSelected };
    }

    componentDidMount() {
        const { getFilter } = this.props;

        const value = this.getSelected();
        if (value && value.length > 0) {
            this.applyFilter(value);
        }

        // export onFilter function to allow users to access
        if (getFilter) {
            getFilter((filterVal) => {
                this.dropdown.value = filterVal;
                this.applyFilter(filterVal);
            });
        }
    }

    componentDidUpdate(prevProps) {
        let needFilter = false;
        if (this.props.defaultValue !== prevProps.defaultValue) {
            needFilter = true;
        } else if (!optionsEquals(this.props.options, prevProps.options)) {
            needFilter = true;
        }
        if (needFilter) {
            this.applyFilter(getSelections(this.dropdown));
        }
    }

    getDefaultValue() {
        const { filterState, defaultValue } = this.props;
        if (filterState && typeof filterState.filterVal !== 'undefined') {
            return filterState.filterVal;
        }
        return defaultValue;
    }

    getOptions() {
        const { options } = this.props;

        if (typeof options === 'function') {
            return Object.entries(options()).map(([k, v]) => {return {key: k, label: v}})
        }
        return options;
    }

    getSelected() {
        const { selected } = this.props;

        if (typeof selected === 'function') {
            return selected();
        }
        return selected;
    }

    cleanFiltered() {
        const value = (this.props.defaultValue !== undefined) ? this.props.defaultValue : [];
        this.dropdown.value = value;
        this.applyFilter(value);
    }

    applyFilter(value) {
        if (value.length === 1 && value[0] === '') {
            value = [];
        }
        this.setState(() => ({ isSelected: value.length > 0 }));
        this.props.onFilter(this.props.column, 'MULTISELECT')(value);
    }

    filter(e) {
        if (e.length == this.dropdown.getOptions().length) {
            this.applyFilter([]);

        } else {
            this.applyFilter(e);
        }
    }

    render() {
        const {
            id,
            style,
            className,
            filterState,
            defaultValue,
            onFilter,
            column,
            options,
            comparator,
            withoutEmptyOption,
            caseSensitive,
            getFilter,
            title,
            preselectAll,
            ...rest
        } = this.props;

        const selectClass =
            `filter select-filter form-control ${className} ${this.state.isSelected ? '' : 'placeholder-selected'}`;
        const elmId = `multiselect-filter-column-${column.dataField}${id ? `-${id}` : ''}`;

        return (
            <DropdownMultiselectCustom
                ref={(n) => { this.dropdown = n}}
                id={ elmId }
                options={ this.getOptions() }
                selected={ this.getSelected() }
                name={column.dataField}
                placeholder={title}
                showSelectToggle={true}
                preselectAll={preselectAll}
                handleOnChange={ (e) => this.filter(e) }
            />
        )
    }
}

MultiSelectFilter.propTypes = {
    onFilter: PropTypes.func.isRequired,
    column: PropTypes.object.isRequired,
    options: PropTypes.oneOfType([PropTypes.func, PropTypes.array]).isRequired,
    id: PropTypes.string,
    filterState: PropTypes.object,
    comparator: PropTypes.oneOf(['LIKE', '=']),
    placeholder: PropTypes.string,
    style: PropTypes.object,
    className: PropTypes.string,
    withoutEmptyOption: PropTypes.bool,
    defaultValue: PropTypes.array,
    caseSensitive: PropTypes.bool,
    getFilter: PropTypes.func
};

MultiSelectFilter.defaultProps = {
    defaultValue: [],
    filterState: {},
    className: '',
    withoutEmptyOption: false,
    comparator: '=',
    caseSensitive: true,
    preselectAll: true,
    id: null
};

export const multiSelectFilter = (props = {}) => ({
    Filter: MultiSelectFilter,
    props
});