import { set } from "lodash-es";
import React, { useState } from "react";
import { Button } from "react-bootstrap";

export default function ConfirmButton({
  className = "",
  variant = "primary",
  confirmationText = "Sure?",
  confirmationVariant = "danger",
  onConfirmed = null,
  savingText = "Saving...",
  handleSaving = false,
  ...props
}) {
  const [ confirming, setConfirming ] = useState(false);
  const [ saving, setSaving ] = useState(false);

  function confirmedClick(event) {
    event.stopPropagation();
    event.preventDefault();
    if (!confirming) {
      setConfirming(true);
      return;
    } else {
        setConfirming(false);
        if (handleSaving) {
          setSaving(true);
        }
        if (onConfirmed) {
          onConfirmed(event);
        }
    }
  }

  return (
    <Button
      className={`ConfirmButton ${className}`}
      disabled={saving}
      variant={confirming ? confirmationVariant : variant}
      onClick={confirmedClick}
      {...props}
    >
      {confirming ? confirmationText : (saving ? savingText : props.children)}
    </Button>
  );
}