import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import NotFound from './containers/NotFound';
import Signup from './containers/Signup';
import Logout from './containers/Logout';
import Trends from './containers/Trends';
import CompanyMappings from './containers/CompanyMappings';
import Toplists from './containers/Toplists';

const Page = (props) => {
  useEffect(() => {
    document.title = props.title || "";
  }, [props.title]);
  return props.children;
};

export default function Routes() {
  return (
    <Switch>
      <Route
        exact
        path="/"
        render={(props) => (
          <Page title="Toplists">
            <Toplists {...props} />
          </Page>
        )}
      />
      <Route exact path="/toplist">
        <Toplists />
      </Route>
      <Route
        exact
        path="/signup"
        render={(props) => (
          <Page title="Signup">
            <Signup {...props} />
          </Page>
        )}
      />
      <Route exact path="/logout">
        <Logout />
      </Route>
      <Route
        exact
        path="/trends"
        render={(props) => (
          <Page title="Trends">
            <Trends {...props} />
          </Page>
        )}
      />

      <Route
        exact
        path="/mappings"
        render={(props) => (
          <Page title="Company mappings">
            <CompanyMappings {...props} />
          </Page>
        )}
      />

      <Route>
        <NotFound />
      </Route>
    </Switch>
  );
}
